<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        <div>
            <TabsComp :tabs="tabs" />
            <v-row class="mainrow mt-2 mb-2" :style="`margin-inline-start:2px;direction:`+lang.dir+`;margin-top:-8px !important;`">
                <v-row class="mt-5">
                    <v-col cols="12" md="12">
                        <form autocomplete="off">
                            <div class="searchTop">
                                <div class="searchBar">
                                    <div style="width:200px">
                                        <div class="mobileFont">{{lang.chose_from_list}} {{lang.start_date}}</div>
                                        <b-input-group>
                                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="$store.state.daysList"/>
                                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="$store.state.monthsList" />
                                            <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="sd.year" :options="$store.state.yearList" />
                                        </b-input-group>
                                    </div>
                                    <div style="width:200px">
                                        <div class="mobileFont">{{lang.chose_from_list}} {{lang.end_date}}</div>
                                        <b-input-group>
                                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="$store.state.daysList"/>
                                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="$store.state.monthsList" />
                                            <b-form-select class="inborder text-center" style="width:34%;background:#efefef;" v-model="ed.year" :options="$store.state.yearList" />
                                        </b-input-group>
                                    </div>
                                    <div>
                                        <div>&nbsp;</div>
                                        <b-button variant="light" style="padding-top:2px !important; padding-bottom:2px !important;background:blue;color:#fff;width:75px;" class="btnMobile"  @click="getCurrentReport()">{{lang.search}}</b-button>
                                    </div>
                                </div>
                                
                            </div>
                        </form>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :style="`direction:${lang.dir};padding-inline-end:30px;`">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th :class="`backBlack text-center ${item.class}`" v-for="(item, index) in headers" :key="index" style="">{{ item.text }}</th>
                                </tr>
                                <tr>
                                    <th :colspan="headers.length - 1" style="font-size:0.1rem !important;height:0.5rem !important;border-inline:none !important;"></th>
                                    <th style="font-size:0.1rem !important;height:0.5rem !important;border-inline:none !important;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in reportRow" :key="index"> 
                                    <td class="text-center">{{ item.counter }}</td>
                                    <td class="text-center">{{ item.cardid }}</td>
                                    <td class="text-center">{{ item.received_date }}</td>
                                    <td class="text-center">{{ item.work_type }}</td>
                                    <td class="text-center">{{ item.customer_name }}</td>
                                    <td class="text-center">{{ item.model }}</td>
                                    <td class="text-center">{{ item.plate_number }}</td>
                                    <td class="text-center">{{ item.received_date }}</td>
                                    <td class="text-center">{{ item.worker_name }}</td>
                                    <td class="text-center">{{ item.item_name }}</td>
                                    <td class="text-center">{{ item.price }}</td>
                                    <td class="text-center">{{ $RoundNum(parseFloat(item.percentage) * parseFloat(item.price) / 100,2) }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="backBlack text-center" colspan="4">عدد اوامر العمل</th>
                                    <th class=" text-center">{{ counter }}</th>
                                    <th class="backBlack text-center" colspan="2">عدد السيارات</th>
                                    <th class=" text-center">{{ plates_num }}</th>
                                    <th class="backBlack text-center"></th>
                                    <th class="backBlack text-center">الاجمالي</th>
                                    <th class=" text-center">{{ prices }}</th>
                                    <th class="backBlack text-center"></th>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                        <br>
                        
                    </v-col>
                </v-row>
            </v-row>
        </div>
        <br><br>
        <Footer style="margin-top:20px;" />
    </div>
</template>

<script>
import HeaderPortrate from '@/components/Header-Portrate.vue'
import Footer from '@/components/Footer.vue'
import ExportsPrint from '@/components/exports-print.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios'
export default{
    components: {HeaderPortrate, Footer, ExportsPrint, TabsComp},
    data() {
        return {
            search: [],
            counter: 0,
            plates_num: 0,
            prices: 0,
            sd: {
                day: 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            reportRow: [],
            income_total: 0,
            expense_total: 0,
            cash_total: 0,
            other_total: 0,
            balance: 0,
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function(){
            let day = this.sd.day;
            let month = this.sd.month;
            if(this.sd.day <10) day = "0"+day;
            if(this.sd.month <10) month = "0"+month;
            return this.sd.year + '-' + month + '-' + day
        },
        edate: function(){
            let day = this.ed.day;
            let month = this.ed.month;
            if(this.ed.day <10) day = "0"+day;
            if(this.ed.month <10) month = "0"+month;
            return this.ed.year + '-' + month + '-' + day
        },
        tabs: function() {
          const menu = this.$store.getters['state'].menu;
          
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            // console.log("menu",this.$store.getters['state'].licenseType)
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
      },
      headers: function()
      {
        return [
            {
              text: "#",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "رقم التشغيل",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "التاريخ",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "نوع الفاتورة",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "العميل",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "السيارة",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "رقم اللوحة",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "تاريخ التشغيل",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "العامل",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "الخدمة",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },            
            {
              text: "قيمة المباع",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: "نسبة العامل",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
        ]
      }
    },
    methods: {
        collectSearch(id){
           return {
            module: 'worksReps',
                elements: {
                    sdate: this.sdate,
                    edate: this.edate,
                },
            }
            
        },
        
        getCurrentReport()
        {
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            const cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('type','getWorkReps'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            axios.post(
            this.$store.state.SAMCOTEC.r_path,
                post
            ).then((response) => {
                this.reportRow = response.data.results.data;
                this.counter = response.data.results.counter;
                this.plates_num = response.data.results.plates_num;
                this.prices = response.data.results.prices;
                
            })
        }
    },
    created() {
        this.getCurrentReport();
    }
}
</script>